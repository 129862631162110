import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import '../Styles/Spinner.css';

const Spinner = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/landing'); // Cambia a la ruta correcta de tu página de destino
    }, 3000);

    return () => clearTimeout(timer); // Limpiar el temporizador si el componente se desmonta
  }, [navigate]);

  return (
    <div className="spinner-container">
      <img
        src="/logo.png" // Cambia a la ruta correcta de tu logo
        alt="Logo"
        className="logo"
      />
      <RingLoader size={60} color="#ea5221" />
    </div>
  );
};

export default Spinner;
