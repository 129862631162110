import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Spinner from './Pages/Spinner';
import Landing from './Pages/Landing'; // Asegúrate de tener una página de destino

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Spinner />} />
      <Route path="/landing" element={<Landing />} /> 
    </Routes>
  </Router>
);

export default App;
