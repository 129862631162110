import React, { useState } from 'react';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

const Landing = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 70,
        behavior: 'smooth'
      });
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-[#313a5b] text-white w-full top-0 z-50">
        <nav className="bg-[#313a5b] p-4">
          <div className="container mx-auto flex justify-between items-center">
            <div>
              <img src="/logo2.png" alt="Logo" className="w-24" />
            </div>
            <div className="hidden md:flex space-x-4">
              <button onClick={() => scrollToSection('hero')} className="text-white hover:text-gray-400">Inicio</button>
              <button onClick={() => scrollToSection('services')} className="text-white hover:text-gray-400">Servicios</button>
              <button onClick={() => scrollToSection('location')} className="text-white hover:text-gray-400">Sobre Nosotros</button>
              <button onClick={() => scrollToSection('contact')} className="text-white hover:text-gray-400">Contacto</button>
            </div>
            <div className="md:hidden flex items-center top-10">
              <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className={`md:hidden absolute top-20 left-0 w-full bg-[#3c4a7d] text-white ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
            <button
              onClick={() => { toggleMobileMenu(); scrollToSection('hero'); }}
              className="block w-full px-4 py-2 text-left"
              style={{ backgroundColor: '#3c4a7d', transition: 'background-color 0.3s' }}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#ea5221'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#3c4a7d'}
            >
              Inicio
            </button>
            <button
              onClick={() => { toggleMobileMenu(); scrollToSection('services'); }}
              className="block w-full px-4 py-2 text-left"
              style={{ backgroundColor: '#3c4a7d', transition: 'background-color 0.3s' }}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#ea5221'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#3c4a7d'}
            >
              Servicios
            </button>
            <button
              onClick={() => { toggleMobileMenu(); scrollToSection('location'); }}
              className="block w-full px-4 py-2 text-left"
              style={{ backgroundColor: '#3c4a7d', transition: 'background-color 0.3s' }}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#ea5221'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#3c4a7d'}
            >
              Sobre Nosotros
            </button>
            <button
              onClick={() => { toggleMobileMenu(); scrollToSection('contact'); }}
              className="block w-full px-4 py-2 text-left"
              style={{ backgroundColor: '#3c4a7d', transition: 'background-color 0.3s' }}
              onMouseEnter={(e) => e.target.style.backgroundColor = '#ea5221'}
              onMouseLeave={(e) => e.target.style.backgroundColor = '#3c4a7d'}
            >
              Contacto
            </button>
          </div>
        </nav>
      </header>

      <section id="hero" className="bg-cover bg-center h-96 flex items-center justify-center" style={{ backgroundImage: 'url(/hero-image.jpg)' }}>
        <div className="bg-black bg-opacity-50 p-4 rounded-md">
          <h2 className="text-white text-3xl md:text-5xl font-bold text-center">Impresión de calidad, rápido y fácil</h2>
        </div>
      </section>

      <section id="services" className="py-12 px-6">
        <h2 className="text-3xl font-bold text-center mb-6">Nuestros Servicios</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="p-6 bg-white shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">Fotocopias</h3>
            <p>Fotocopias en blanco y negro, color y planos de gran formato.</p>
          </div>
          <div className="p-6 bg-white shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">Escaneo</h3>
            <p>Escaneo de documentos y digitalización de gran formato.</p>
          </div>
          <div className="p-6 bg-white shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">Encuadernación</h3>
            <p>Encuadernaciones profesionales para todo tipo de documentos.</p>
          </div>
          <div className="p-6 bg-white shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">Vinilos y Lonas</h3>
            <p>Impresión de cartelería, lonas y vinilos decorativos personalizados.</p>
          </div>
          <div className="p-6 bg-white shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">Impresión desde USB</h3>
            <p>Imprime fácilmente desde tus dispositivos USB o envía por correo electrónico.</p>
          </div>
          <div className="p-6 bg-white shadow-md hover:shadow-lg transition-shadow">
            <h3 className="font-bold text-xl mb-2">Fax y Otros Servicios</h3>
            <p>Ofrecemos servicio de fax, plastificados y más.</p>
          </div>
        </div>
      </section>

      <section id="location" className="py-12 px-6 bg-gray-200">
        <h2 className="text-3xl font-bold text-center mb-6">Ubicación</h2>
        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className="lg:w-1/2">
            <p className="text-lg mb-4 text-center lg:text-left">
              Nos encontramos en la Avenida Marqués de Figueroa, 65, Fene, A Coruña.
              Para cualquier consulta, contáctanos por teléfono o correo electrónico.
            </p>
          </div>
          <div className="lg:w-1/2">
            <iframe
              title="Ubicación Cofer Fene"
              src="https://maps.google.com/maps?q=cofer%20fene&t=&z=13&ie=UTF8&iwloc=&output=embed"
              className="w-full"
              height="300"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </section>

      <section id="contact" className="py-12 px-6" style={{ backgroundColor: '#313a5b', color: 'white' }}>
        <h2 className="text-3xl font-bold text-center mb-6">Contacto</h2>
        <div className="flex flex-col items-center">
          <p className="text-lg">Teléfono: 881 307 264</p>
          <p className="text-lg">Correo: coferfene@hotmail.com</p>
        </div>
      </section>

      <footer className="bg-[#3c4a7d] text-white p-4 text-center">
        
        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://www.facebook.com/Coferfene/" target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare className="text-xl hover:text-gray-400" />
          </a>
          <a href="https://www.instagram.com/copisteriacoferfene" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-xl hover:text-gray-400" />
          </a>
          
        </div>
        <p>© 2024 Cofer Fene. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default Landing;
